import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';

import { formatDate } from '#helpers/displayUtils.js';

const UserEdit = ({ userData, onSave }) => {
    const history = useHistory(); // Hook to enable programmatic navigation
    const displayValue = (value) => value || 'N/A';

    const [formData, setFormData] = useState({
        name: userData.name || '',
        address: userData.address || '',
        city: userData.city || '',
        state: userData.state || '',
        zip: userData.zip || '',
        birthDay: userData.birthDay || '',
        isVIP: userData.isVIP || false
    });

    // Add this useEffect hook
    useEffect(() => {
        setFormData({
            name: userData.name || '',
            address: userData.address || '',
            city: userData.city || '',
            state: userData.state || '',
            zip: userData.zip || '',
            birthDay: userData.birthDay || '',
            isVIP: userData.isVIP || false
        });
    }, [userData]);

    const handleSave = () => {
        onSave(formData);
    };
    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const goBack = () => {
        history.push(`/users/view/${userData.id}`);
    };

    return (
        <React.Fragment>
            <Button color="primary" onClick={goBack} style={{ marginBottom: '20px' }}>
                Back to the {userData.email} Details
            </Button>
            <Container className="user-details">
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md={4}>
                                        <strong>Email:</strong>
                                        <br /> {displayValue(userData.email)}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="name">
                                                <strong>Name:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="address">
                                                <strong>Street:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="address"
                                                id="address"
                                                value={formData.address}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="city">
                                                <strong>City:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="state">
                                                <strong>State:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="state"
                                                id="state"
                                                value={formData.state}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="zip">
                                                <strong>Zip:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="zip"
                                                id="zip"
                                                value={formData.zip}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="birthDay">
                                                <strong>DOB:</strong>
                                            </Label>
                                            <Input
                                                type="date"
                                                name="birthDay"
                                                id="birthDay"
                                                value={formData.birthDay}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="isVIP">
                                                <strong>VIP:</strong>
                                            </Label>
                                            <Input
                                                type="checkbox"
                                                name="isVIP"
                                                id="isVIP"
                                                checked={formData.isVIP}
                                                onChange={handleCheckChange}
                                                style={{marginLeft: `1em`}}
                                            />
                                        </FormGroup>

                                        <Button color="primary" onClick={handleSave}>
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default UserEdit;
